import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Container from 'app/components/Container';

const Root = styled.header`
    background: url('/images/secondary-bg.svg') no-repeat 50% 100%;
    background-size: cover;
    color: var(--clr-white);
    padding: 12rem 0;

    @media (min-width: 500px) {
        padding: 20rem 0 10rem;
    }
`;

const Title = styled.h1`
    font-family: var(--ff-title);
    font-size: var(--fs-xlrg);
    line-height: var(--lh-title);
    max-width: 66rem;

    strong {
        font-weight: inherit;

        &:nth-child(1) {
            color: var(--clr-theme-3);
        }

        &:nth-child(2) {
            color: var(--clr-theme-1);
        }
    }

    @media (min-width: 500px) {
        font-size: var(--fs-3xlrg);
    }
`;

const Inner = styled.div`
    position: relative;

    &::before {
        background: url('/images/logo-mark.svg') no-repeat center;
        background-size: contain;
        bottom: -16rem;
        content: '';
        height: 16rem;
        position: absolute;
        right: 7%;
        transform: rotate(-45deg);
        width: 16rem;
    }

    @media (min-width: 500px) {
        &::before {
            bottom: -14rem;
            height: 18rem;
            width: 18rem;
        }
    }

    @media (min-width: 700px) {
        &::before {
            bottom: -11.5rem;
            height: 22rem;
            width: 22rem;
        }
    }
`;

const SecondaryHeader = ({ title }) => (
    <Root>
        <Container>
            <Inner>
                <Title>{title}</Title>
            </Inner>
        </Container>
    </Root>
);

SecondaryHeader.propTypes = {};

SecondaryHeader.defaultProps = {};

export default SecondaryHeader;
