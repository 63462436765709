import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Container from 'app/components/Container';
import UniformBlock from 'app/components/UniformBlock';

const data = [
    {
        title: 'iPerform T-Shirt',
        image: 'tshirt',
        isCompulsory: true,
        description: '100% cotton. Taped neck. Ribbed collar. Tubular body. Twin needle stitching.',
        price: 12,
        sizes: ['5-6', '7-8', '9-11', '12-13', 'XS', 'S', 'M', 'L', 'XL'],
    },
    {
        title: 'iPerform Hoodie',
        image: 'hoodie',
        isCompulsory: false,
        description: '80% ringspun cotton / 20% polyester.',
        price: 25,
        sizes: ['5-6', '7-8', '9-11', '12-13', 'XS', 'S', 'M', 'L', 'XL'],
    },
];

const Root = styled.section`
    background-color: var(--clr-grey-light);
    padding: 6rem 0;
    position: relative;

    @media (min-width: 800px) {
        padding: 12rem 0;
    }
`;

const UniformSection = () => (
    <Root>
        <Container>
            {data.map((item, index) => (
                <UniformBlock key={item.image} {...item} isReverse={index % 2 === 0} />
            ))}
        </Container>
    </Root>
);

UniformSection.propTypes = {};

UniformSection.defaultProps = {};

export default UniformSection;
