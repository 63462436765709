import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Root = styled.dl`
    font-size: var(--fs-sml);

    @media (min-width: 500px) {
        display: flex;
    }

    @media (min-width: 800px) {
        display: block;
    }

    @media (min-width: 1000px) {
        display: flex;
    }
`;

const Group = styled.div`
    margin-bottom: 2rem;

    @media (min-width: 500px) {
        margin-bottom: 0;
        margin-right: 4rem;
    }

    @media (min-width: 800px) {
        margin-bottom: 2rem;
        margin-right: 0;
    }

    @media (min-width: 1000px) {
        margin-bottom: 0;
        margin-right: 4rem;
    }

    &:last-child {
        margin-bottom: 0;
        margin-right: 0;
    }
`;

const Term = styled.dt`
    font-weight: var(--fw-bold);
    letter-spacing: 0.1em;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
`;

const Def = styled.dd`
    background-color: var(--clr-grey-light);
    border-radius: 1rem;
    padding: 0.5rem 1.2rem;
`;

const List = styled.ul`
    display: flex;
    flex-wrap: wrap;
`;

const ListItem = styled.li`
    white-space: nowrap;

    &:not(:last-child) {
        margin-right: 2rem;
    }
`;

const UniformMeta = ({ price, sizes }) => (
    <Root>
        <Group>
            <Term>Price</Term>
            <Def>&pound;{price}</Def>
        </Group>
        <Group>
            <Term>Sizes</Term>
            <Def>
                <List>
                    {sizes.map((item) => (
                        <ListItem key={item}>{item}</ListItem>
                    ))}
                </List>
            </Def>
        </Group>
    </Root>
);

UniformMeta.propTypes = {};

UniformMeta.defaultProps = {};

export default UniformMeta;
