import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Container from 'app/components/Container';
import ContactButton from 'app/components/ContactButton';

const Root = styled.div`
    background-color: var(--clr-grey-light);
    padding-bottom: 6rem;
    position: relative;

    @media (min-width: 800px) {
        padding-bottom: 12rem;
    }

    &::before {
        background-color: var(--clr-theme-1);
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 50%;
    }
`;

const Content = styled.aside`
    align-items: center;
    background-color: var(--clr-dark);
    border-radius: 1rem;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4rem;
    position: relative;
    text-align: center;
`;

const Description = styled.p`
    font-size: var(--fs-med);
    margin-bottom: 3rem;
`;

const UniformCta = () => (
    <Root>
        <Container>
            <Content>
                <Description>
                    For more information or to place an order, please get in touch
                </Description>
                <ContactButton />
            </Content>
        </Container>
    </Root>
);

UniformCta.propTypes = {};

UniformCta.defaultProps = {};

export default UniformCta;
