import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ContentTitle from 'app/components/ContentTitle';
import UniformCompulsory from 'app/components/UniformCompulsory';
import UniformMeta from 'app/components/UniformMeta';

const Root = styled.div`
    position: relative;

    &:not(:last-child) {
        margin-bottom: 8rem;
    }
`;

const Content = styled.div`
    background-color: var(--clr-white);
    border-radius: 1rem;
    overflow: hidden;
    padding: 3rem 2rem;
    padding-top: 110%;
    position: relative;

    &::before {
        background-color: ${(props) =>
            props.isReverse ? 'var(--clr-theme-1)' : 'var(--clr-theme-2)'};
        border-radius: 1rem 1rem 0 0;
        content: '';
        height: 1rem;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
    }

    &::after {
        background: url('/images/uniform-bg.svg') no-repeat;
        background-position: 50% 50%;
        background-size: contain;
        bottom: 45%;
        content: '';
        left: -10%;
        pointer-events: none;
        position: absolute;
        right: -10%;
        top: -20%;
    }

    @media (min-width: 500px) {
        padding-top: 46rem;
    }

    @media (min-width: 800px) {
        padding: 4.5rem 4rem 4rem;
        ${(props) => (props.isReverse ? 'padding-right: 45%;' : 'padding-left: 45%;')}

        &::after {
            background-position: ${(props) => (props.isReverse ? '100% 50%' : '0 50%')};
            bottom: -45%;
            ${(props) => (props.isReverse ? 'left: 60%' : 'right: 60%')};
            top: -40%;
        }
    }
`;

const Description = styled.div`
    margin-bottom: 2rem;
`;

const Image = styled.img`
    height: calc(3rem + 50%);
    left: 5%;
    object-fit: contain;
    object-position: 50% 0;
    position: absolute;
    top: -3rem;
    width: 90%;
    z-index: 2;

    @media (min-width: 500px) {
        bottom: 40%;
    }

    @media (min-width: 800px) {
        height: calc(3rem + 95%);
        left: auto;
        ${(props) => (props.isReverse ? 'right: 5%;' : 'left: 5%;')}
        object-position: 50%;
        width: 35%;
    }

    /* display: none;
    left: 50%;
    max-width: 44rem;
    position: absolute;
    top: -3rem;
    transform: translateX(-50%);
    width: 80%;
    z-index: 2;

    @media (min-width: 800px) {
        display: none;
        height: calc(100% + 2rem);
        left: auto;
        ${(props) => (props.isReverse ? 'left: 52%' : 'right: 52%')};
        transform: ${(props) => (props.isReverse ? 'translateX(50%)' : 'translateX(-50%)')};
        width: auto;
    } */
`;

const UniformBlock = ({ description, isCompulsory, image, isReverse, price, sizes, title }) => (
    <Root>
        <Content isReverse={isReverse}>
            <ContentTitle color={isReverse ? 'theme-1' : null}>{title}</ContentTitle>
            <UniformCompulsory isCompulsory={isCompulsory} />
            <Description>{description}</Description>
            <UniformMeta price={price} sizes={sizes} />
        </Content>
        <Image src={`images/${image}.png`} isReverse={isReverse} />
    </Root>
);

UniformBlock.propTypes = {};

UniformBlock.defaultProps = {};

export default UniformBlock;
