import React from 'react';

import Introduction from 'app/components/Introduction';
import Scene from 'app/components/Scene';
import SecondaryHeader from 'app/components/SecondaryHeader';
import UniformCta from 'app/components/UniformCta';
import UniformSection from 'app/components/UniformSection';

const HomePage = () => (
    <Scene title="Uniforms | iPerform Academy of Theatre Arts">
        <SecondaryHeader title="Uniforms" />
        <Introduction title="What is the Uniform?">
            <p>Uniform is to be worn with plain black, full length leggings.</p>
            <p>
                Split sole Jazz shoes are also to be worn. These can be ordered in sizes child 10 up
                to adults 9.
            </p>
            <p>Uniform should be purchased by the end of the first half term’s attendance.</p>
        </Introduction>
        <UniformSection />
        <UniformCta />
    </Scene>
);

export default HomePage;
