import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Root = styled.p`
    display: flex;
    margin: -1rem 0 2rem;
`;

const Inner = styled.span`
    background-color: ${(props) =>
        props.isCompulsory ? 'var(--clr-theme-1)' : 'var(--clr-theme-2)'};
    border-radius: 0.5rem;
    color: var(--clr-white);
    font-size: var(--fs-sml);
    font-weight: var(--fw-bold);
    padding: 0.5rem 1rem;
`;

const UniformCompulsory = ({ isCompulsory }) => (
    <Root>
        <Inner isCompulsory={isCompulsory}>{isCompulsory ? 'Compulsory' : 'Optional'}</Inner>
    </Root>
);

UniformCompulsory.propTypes = {};

UniformCompulsory.defaultProps = {};

export default UniformCompulsory;
